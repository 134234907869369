@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Change Autocomplete styles in Chrome*/
/* https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
}

*:focus-visible {
  outline: none;
}

.Toastify .toast-progress {
  background: #ec2e5b;
  height: 3px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.CarrouselPicker .swiper-wrapper {
  align-items: end;
}

.swiper-grow.swiper-container {
  height: auto !important;
}

.swiper-grow.swiper-slide {
  height: auto !important;
}

.bullet-blue.swiper-pagination-bullet-active {
  background: #89f5e1 !important;
}

.bullet-blue.swiper-pagination-bullet {
  background: #000;
}
